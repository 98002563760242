<template>
  <span :class="cssClasses" v-if="title">
    {{ title }}
  </span>

  <span :class="cssClasses" v-else>
    <slot/>
  </span>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: false,
  },

  bold: {
    type: Boolean,
    required: false,
  },
})

const cssClasses = ref('select-none text-xs text-gray-600 italic')

onMounted(() => {
  if (props.bold) {
    cssClasses.value += ' font-semibold'
  }
})
</script>